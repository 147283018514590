<template>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">
        <div class="row justify-content-end px-4">
          <div class="row col-10 justify-content-end">
            <!-- <base-select :onChange="(val) => onFilterChange('country', val)" :model="country" :label="countryFilter.label"
              class="mr-2 w-25 w-full" :options="countryFilter.options" :default="country"></base-select>
            <base-select :onChange="(val) => onFilterChange('device', val)" :model="device" :label="deviceFilter.label"
              class="mr-2 w-25 w-full" :options="deviceFilter.options" :default="device"></base-select> -->

          </div>
        </div>

        <div v-if="selectedItems.length > 0">
          <base-button @click="updateUser(selectedItems, isMajoritySelectedActive)" size="sm"  :type="isMajoritySelectedActive ? 'danger' : 'primary'" fill>{{ isMajoritySelectedActive ? "Disable All" : "Enable all" }}</base-button>
        </div>

        <div class="table-responsive">
          <user-table
            :currentPage="currentPage"
            :perPage="perPage"
            :updateUser="updateUsers"
            :data="table1.data" 
            :columns="table1.columns"
            thead-classes="text-primary"
            :onSelect="onSelect"
            :selectedItems="selectedItems"
            :loading="loading"
            :loadings="loadings"
          >
          </user-table>
        <div v-if="table1?.data?.length > 0 && !loading">
            <pagination
              :totalItems="table1.data.length"
              :totalPages="totalPages"
              :currentPage="currentPage"
              :onSelect="selectPage"
              :perPage="perPage"
              :onPerPageChange="onPerPageChange"
            ></pagination>
        </div>
        <div v-else-if="loading" style="height: 40px; width: 100%;" class="row justify-content-center align-items-center">
            <clip-loader color="#C153EC" size="40px" :loading="loading"></clip-loader>
          </div>
        <div v-else class="row justify-content-center">
          <span>No results found</span>
        </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>

import { UserTable } from "@/components";
import { BaseSelect } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from 'vuex';
import Countries from '../assets/data/countries.json'
import NotificationTemplate from './Notifications/NotificationTemplate';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const tableColumns = [ "User ID", "Total Clicks", "Total Conversions", "IP", "Country", "Device", "Status", "control"];

export default {
  components: {
    UserTable,
    BaseSelect,
    Pagination,
    ClipLoader

  },
  mounted() {
    const countries = Countries.map(x => {
      return {
        title: x.name,
        value: x.code
      }
    })
    this.countryFilter.options = [...this.countryFilter.options, ...countries]
    this.getUsers()
  },
  updated() {
    // console.log('----users updated', this.totalPages);
  },
  data() {
    return {
      table1: {
        title: "Users",
        columns: [...tableColumns],
        data: []
      },
      countryFilter: {
        label: "Country",
        name: "country",
        options: [{ title: "ALL", value: "all" }],
      },
      deviceFilter: {
        label: "Device",
        name: "device",
        options: [{ title: "ALL", value: "all" }, { title: "Web", value: "Web" }, { title: "Android", value: "Android" }, { title: "Iphone", value: "iPhone" }],
      },
      country: "all",
      device: "all",
      currentPage: 1,
      perPage: 10,
      selectedItems: [],
      loading: false,
      loadings: null
    };
  },
  computed: {
    totalPages() {
      return Math.round(this.users.length / this.perPage)
    },
    isMajoritySelectedActive() {
      const item = this.users.find(x => x.id == this.selectedItems[0])
      if (item && item.active)return true;
      return false
    },
    ...mapGetters([
      'users'
    ])
  },
  methods: {
    getUsers: async function () {
      this.loading = true;
      await this.$store.dispatch("getUsers", {country: this.country, device: this.device});
      console.log('---this.users', this.users);
      this.table1.data = [...this.users]
      this.loading = false;
      this.currentPage = 1
    },
    onFilterChange: function(key, val) {
      this[key] = val;
      this.getUsers()
    },
    updateUsers: async function(status, id) {
      this.loadings = {blocked: id};
      console.log('[--this.laoidngs, ]', this.loadings, id, id[0]);
      await this.$store.dispatch("updateUsers", {blocked: !status, ids: [id]});
      // await this.getUsers()
      this.loadings = null;
    },
    selectPage: function (page) {
      if (page > this.totalPages || page < 1) return;
      this.currentPage = page
    },
    onSelect: function (ids, value) {
      if (value) {
        this.selectedItems = [...new Set(this.selectedItems.concat(ids))]
      } else {
        this.selectedItems = []
      }
    },
    onPerPageChange: function (val) {
      console.log('-onPerPageChange', val)  
      if (!isNaN(val)) {
        this.perPage = val;
        this.currentPage = 1
      } 
    },
    notifyVue(success, message) {
      this.$notify({
          message: message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: success ? "success" : "danger",
          timeout: 5000
        });
      }
  },

};
</script>
<style>

</style>
