<template>
  <card type="offerwall">
    <h5 slot="header" class="title">Offerwall Settings</h5>
    <div class="">
      <label class="control-label">OFFERWALL NAME:</label><br/>
      <span class="mr-2">{{ offerwall?.name }}</span>
      <i style="cursor: pointer;" @click="copy(offerwall?.name)" class="tim-icons icon-single-copy-04"></i>
    </div>
    <div class="mt-3">
      <label class="control-label">API KEY:</label><br/>
      <span class="mr-2">{{ offerwall?.api_key }}</span>
      <i style="cursor: pointer;" @click="copy(offerwall?.api_key)" class="tim-icons icon-single-copy-04"></i>
    </div>
    <div class="mt-3">
      <label class="control-label">Secret Key:</label><br/>
      <span class="mr-2">{{ offerwall?.secret_key }}</span>  
      <i style="cursor: pointer;" @click="copy(offerwall?.secret_key)" class="tim-icons icon-single-copy-04"></i>
    </div>
    <div class="mt-3">
      <label class="control-label">Offerwall url:</label><br/>
      <span class="mr-2">{{ offerwall?.link }}</span>
      <i style="cursor: pointer;" @click="copy(offerwall?.link)" class="tim-icons icon-single-copy-04"></i>
    </div>
    <div class="mt-3">
      <label class="control-label">Callback url:</label><br/>
      <span class="mr-2">{{ offerwall?.postback_url }}</span>
      <i style="cursor: pointer;" @click="copy(offerwall?.postback_url)" class="tim-icons icon-single-copy-04"></i>
    </div>
    <div class="mt-3">
      <label class="control-label">Callback PHP file:</label><br/>
      <base-button @click="downloadCallbackFile()" slot="footer" type="primary" fill>Download File</base-button>
    </div>

  </card>
</template>
<script>
  export default {
    updated() {
    // console.log('-----', this.offerwall);
  },
  created() {
    // console.log('----- offerwall', this.offerwall);
  },
    props: {
      offerwall: {
        type: Object,
        default: {}
      }
    },
    methods: {
      copy: function (text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
      },
      downloadCallbackFile() {
        window.open(this.offerwall.callback_file_url)
      }
    }
  }
</script>
<style>
</style>
